<template>
  <vx-card>
    <vs-row>
      <h4 class="mb-5">Edit Testimony</h4>
    </vs-row>

    <vs-row v-if="!loading" vs-w="12">
      <vs-col vs-w="12">
        <vs-row vs-w="12">
          <vs-col vs-w="12" class="mb-3">
            <span class="mb-2 block text-sm">Brand (*)</span>
            <v-select
              label="name"
              :options="brands"
              :reduce="(brand) => brand.id"
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
              v-model="payload.brand"
            />
            <span class="block text-sm mt-2 text-danger">{{
              errors.first("brand")
            }}</span>
          </vs-col>

          <vs-col vs-w="12" class="mb-3">
            <vs-input class="w-full" label="Name (*)" v-model="payload.name" />
            <span class="block text-sm mt-2 text-danger">{{
              errors.first("name")
            }}</span>
          </vs-col>

          <vs-col vs-w="12" class="mb-3">
            <vs-input
              class="w-full"
              label="Title (*)"
              v-model="payload.title"
            />
            <span class="block text-sm mt-2 text-danger">{{
              errors.first("title")
            }}</span>
          </vs-col>

          <vs-col vs-w="12" class="mb-3">
            <p class="text-sm">Words (*)</p>
            <vs-textarea class="w-full" v-model="payload.words" />
            <span class="block text-sm mt-2 text-danger">{{
              errors.first("words")
            }}</span>
          </vs-col>
          <vs-col vs-w="12" class="mb-3">
            <p class="text-sm">Rating (*)</p>
            <star-rating
              :star-size="20"
              @rating-selected="setRating"
              :rating="payload.rating"
              :show-rating="false"
            />
            <span class="block text-sm mt-2 text-danger">{{
              errors.first("rating")
            }}</span>
          </vs-col>

          <vs-col vs-w="12" class="mt-3">
            <div class="vx-col w-full" @click.prevent="storeData">
              <vs-button class="mr-3 mb-2">Submit</vs-button>
            </div>
          </vs-col>
        </vs-row>
      </vs-col>
    </vs-row>
  </vx-card>
</template>

<script>
// Store Module
import moduleBrandManagement from "@/store/brand-management/moduleBrandManagement.js";
import testimoniesStore from "@/store/brand-testimony";
import vSelect from "vue-select";
import StarRating from "vue-star-rating";

export default {
  name: "TestimonyEdit",

  metaInfo: {
    title: "Edit Testimony",
    titleTemplate: "%s - Vancy Net",
    htmlAttrs: {
      lang: "en",
      amp: true,
    },
  },
  components: {
    vSelect,
    StarRating,
  },

  computed: {
    decryptedID() {
      return this.$secure.decrypt(this.$route.params.id);
    },
    brands() {
      return this.$store.state.brandManagement.brands;
    },
  },

  data: () => ({
    loading: true,
    payload: {
      name: "",
      brand: "",
      title: "",
      words: "",
      rating: 0,
    },
  }),

  methods: {
    setRating: function (rating) {
      this.payload.rating = rating;
    },
    async getData() {
      this.$vs.loading();

      const response = await this.$store.dispatch(
        "testimony/fetchTestimony",
        this.decryptedID
      );
      this.payload.name = response.message.name;
      this.payload.title = response.message.title;
      this.payload.brand = response.message.brand.id;
      this.payload.words = response.message.words;
      this.payload.rating = response.message.rating;
      this.loading = false;
      this.$vs.loading.close();
    },

    async storeData() {
      const formData = new FormData();
      // set formData
      formData.set("name", this.payload.name);
      formData.set("brand_id", this.payload.brand);
      formData.set("title", this.payload.title);
      formData.set("words", this.payload.words);
      formData.set("rating", this.payload.rating);
      formData.append("_method", "PUT");
      const response = await this.$store.dispatch("testimony/updateTestimony", {
        id: this.decryptedID,
        body: formData,
      });
      if (!response.success) {
        this.$catchErrorResponse(response.message);
        return;
      }

      this.$vs.notify({
        title: "Successful",
        text: response.msg_status,
        iconPack: "feather",
        icon: "icon-check-circle",
        color: "success",
      });

      this.$router.push("/brand-testimony");
    },
  },

  created() {
    if (!moduleBrandManagement.isRegistered) {
      this.$store.registerModule("brandManagement", moduleBrandManagement);
      moduleBrandManagement.isRegistered = true;
    }
    if (!testimoniesStore.isRegistered) {
      this.$store.registerModule("testimony", testimoniesStore);
      testimoniesStore.isRegistered = true;
    }
  },
  async mounted() {
    await this.$store.dispatch("brandManagement/fetchBrands");
    await this.getData();
  },
};
</script>

<style></style>
